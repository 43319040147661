<template>
  <section class="mb-8" v-if="noEmpty">
    <div class="font-weight-black">Пищевая ценность продукта:</div>
    <v-row class="bju">
      <v-col cols="auto" v-if="bju.protein">
        <div>Белки</div>
        <span>{{ bju.protein }} г</span>
      </v-col>
      <v-col cols="auto" v-if="bju.fats">
        <div>Жиры</div>
        <span>{{ bju.fats }} г</span>
      </v-col>
      <v-col cols="auto" v-if="bju.carbohydrates">
        <div>Углеводы</div>
        <span>{{ bju.carbohydrates }} г</span>
      </v-col>
      <v-col cols="auto" v-if="bju.calories">
        <div>Калорийность</div>
        <span>{{ bju.calories }} кал</span>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: ["bju"],

  computed: {
    noEmpty() {
      const { protein, fats, carbohydrates, calories } = this.bju;
      return !!(protein || fats || carbohydrates || calories);
    },
  },
};
</script>

<style lang="scss" scoped>
.bju {
  font-size: 14px;
}
</style>