<template>
  <section>
    <div v-for="(group, i) in addons" :key="i" class="mb-6">
      <div class="font-weight-black mb-3">{{ group.subcat_name }}</div>
      <template v-if="!isCustomized(group.sub_item)">
        <v-row
          v-for="(add, k) in group.sub_item"
          :key="k"
          class="addons mb-3 secondary--text"
          align="center"
        >
          <v-col cols="auto">
            <div
              class="check-icon"
              :class="{ active: add.qty }"
              @click="check(add, group)"
            >
              <v-icon color="#fff" v-if="add.qty">{{ icons.mdiCheck }}</v-icon>
            </div>
          </v-col>
          <v-col :class="{ 'dark--text': add.qty }" @click="check(add, group)">
            <span>{{ add.sub_item_name }}</span>
            <span class="ml-2 text-no-wrap"
              >+ {{ add.price }} {{ $store.getters['app/currency'] }}</span
            >
          </v-col>
          <v-col
            cols="auto"
            class="dark--text"
            v-if="group.multi_option != 'one'"
          >
            <v-btn
              fab
              x-small
              color="white"
              @click="minus(add, i)"
              elevation="2"
            >
              <v-icon small>{{ icons.mdiMinus }}</v-icon>
            </v-btn>
            <input
              class="quant dark--text"
              type="text"
              readonly
              value="0"
              v-model="add.qty"
            />
            <v-btn
              fab
              x-small
              color="white"
              @click="plus(add, i)"
              elevation="2"
            >
              <v-icon small>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <div
          v-for="(add, k) in group.sub_item"
          :key="k"
          :class="{ 'addons--active': add.qty }"
          class="addons--custom py-3 mb-3 secondary--text rounded-sm"
        >
          <v-row align="center" justify="space-between">
            <v-col cols="2" class="py-0 text-center ml-3">
              <img
                v-lazy="add.photo"
                :alt="add.sub_item_name"
                :title="add.sub_item_name"
                class="addons--front"
              />
            </v-col>
            <v-col
              :class="{ 'dark--text': add.qty }"
              @click="check(add, group)"
            >
              {{ add.sub_item_name }}
              <div>
                <span>
                  + {{ add.price }} {{ $store.getters['app/currency'] }}</span
                >
              </div>
            </v-col>
            <v-col v-if="group.multi_option != 'one'" class="mr-3" cols="auto">
              <v-btn
                fab
                width="20"
                height="20"
                color="white"
                @click="minus(add)"
                elevation="2"
              >
                <v-icon x-small>{{ icons.mdiMinus }}</v-icon>
              </v-btn>
              <input
                class="quant dark--text"
                type="text"
                readonly
                value="0"
                v-model="add.qty"
              />
              <v-btn
                fab
                width="20"
                height="20"
                color="white"
                @click="plus(add)"
                elevation="2"
              >
                <v-icon x-small>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import noPhoto from '@/assets/images/no-photo.svg'
import { mdiMinus, mdiPlus, mdiCheck } from '@mdi/js'

export default {
  props: ['addons'],

  data: () => ({
    icons: {
      mdiMinus,
      mdiPlus,
      mdiCheck,
      noPhoto,
    },
  }),

  computed: {
    isCustomized() {
      return (group) => {
        if (!Array.isArray(group)) return false
        return group.some(({ photo }) => !!photo)
      }
    },

    /**
     * Return amount of all the activated addons indexes.
     *
     * @external
     * @returns {@link Number} amount of instances.
     */
    qty() {
      return (index) => {
        const addonsDTO = [...this.addons]
        /** @internal claculate all of the qty amouns in the stream. */
        return addonsDTO && addonsDTO[index]
          ? addonsDTO[index].sub_item
              .map?.(({ qty }) => qty)
              .reduce?.((accumulator, addon) => {
                accumulator = accumulator + addon
                return accumulator
              })
          : 0
      }
    },
  },

  methods: {
    minus(add) {
      if (add.qty > 0) {
        add.qty--
      }
    },

    /**
     * Add new item qty into the addons map.
     *
     * @internal
     * @returns void
     */
    plus(add, index) {
      const productAddonsDTO = this.addons && this.addons[index]
      /** @var {@link Number} multiOptionVal */
      const multiOptionVal = +productAddonsDTO?.multi_option_val || Infinity
      const currentQty = this.qty(index) || 0
      if (currentQty < multiOptionVal) {
        add.qty++
      }
    },

    check(add, group) {
      if (group.multi_option == 'one') {
        const qty = add.qty

        group.sub_item?.forEach((el) => {
          el.qty = 0
        })

        if (!group.require_addons) {
          if (qty == 0) {
            add.qty = 1
          } else {
            add.qty = 0
          }
          return
        }
      }

      if (add.qty == 0) {
        add.qty = 1
      } else {
        add.qty = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.addons {
  font-weight: 600;

  &--custom {
    overflow: hidden;

    border-radius: $border-radius-addons-card;
    transition: 0.1s border ease-in-out;
    border: 1px solid transparent;
  }

  &--active {
    border: 1px solid var(--v-primary-base) !important;
  }

  &--front {
    width: 100%;
    height: auto;
    // max-height: 58px;
    background-repeat: no-repeat;
    background-clip: padding-box;
    background-size: contain;
  }
}

.quant {
  width: 32px;
  height: 32px;
  margin: 0 4px;
  text-align: center;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;

  &:focus {
    outline: none;
  }
}
</style>
